"use client";
import { useReducer, createContext } from "react";
import { initialValues, reducer } from "@/contexts/UserContext";

export const UserContext = createContext(null);

export default function Providers({ children }) {
	const [state, dispatch] = useReducer(reducer, initialValues);
	return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
}
