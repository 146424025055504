"use client";

export const initialValues = {
	username: "",
	authenticated: false,
	subscribed: false,
};

export const reducer = (state, action) => {
	switch (action.type) {
		case "changeUser":
			return {
				...state,
				username: action.payload.username,
				authenticated: action.payload.authenticated ?? false,
				subscribed: action.payload.subscribed ?? false,
			};
		default:
			break;
	}
};
