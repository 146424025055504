import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Manuel\\Documents\\ref2site-frontend\\app\\providers.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Manuel\\Documents\\ref2site-frontend\\assets\\logo.png");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Manuel\\Documents\\ref2site-frontend\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Manuel\\Documents\\ref2site-frontend\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Manuel\\Documents\\ref2site-frontend\\node_modules\\next\\font\\google\\target.css?{\"path\":\"app\\\\layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Manuel\\Documents\\ref2site-frontend\\node_modules\\next\\font\\google\\target.css?{\"path\":\"app\\\\layout.js\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer","Flip"] */ "C:\\Users\\Manuel\\Documents\\ref2site-frontend\\node_modules\\react-toastify\\dist\\react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Manuel\\Documents\\ref2site-frontend\\styles\\styles.global.scss");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Manuel\\Documents\\ref2site-frontend\\node_modules\\react-toastify\\dist\\ReactToastify.css");
